<template>
  <div class="container">
    <p class="title">《隐私政策》</p>
    <p><strong>强生（上海）医疗器材有限公司</strong>尊重您的隐私，并希望您熟悉我们如何收集、使用和披露信息。本隐私政策描述了我们或我们的服务提供商通过我们运营和控制的网站或应用程序(以下简称<strong>"服务"</strong>）收集信息的相关实践，您从这些网站或应用程序访问本隐私政策。我们鼓励您在使用本服务或提供任何个人信息之前阅读隐私政策的完整内容。</p><br/>

    <p><strong>在使用服务或向我们提供个人信息之前，请仔细阅读本隐私政策。</strong></p><br/>

    <p><strong>未成年人使用</strong></p>
    <p>本服务并非面向14岁以下的个人，我们请求上述个人不要通过本服务提供个人信息。如果您的子女已经提交了个人信息，而您希望要求删除该等个人信息，请按照下文“联系我们”部分与我们联系。</p><br/>

    <p><strong>信息收集</strong></p>
    <p>我们可能会要求您提交个人信息，以便您能够从特定功能（如订阅通讯、提示/指示，或订单处理）中受益，或参与特定活动（如抽奖或其他促销活动）。您将会被告知哪些信息是必需的，哪 些信息是选填的。</p>
    <p>我们可能会将您提交的信息与我们无论从线上或线下向您收集的其他信息相结合，包括，例如，您的<strong>消费记录</strong>。 我们也可能会将其与我们从其他来源获得的关于您的信息相结合，例如其他强生 关联公司、公开的信息来源（包括来自您公开的社交媒体简介的信息）和其他第三方信息供应商。</p>
    <p>如果您向我们提交任何有关其他个人的个人信息，则表示您有权这样做，并允许我们根据本隐私 政策使用该等信息。</p><br/>

    <p><strong>敏感信息</strong></p>
    <p>除非我们特别要求或请求，否则请勿在本服务提供过程中或通过本服务或其他方式向我们发送或 披露任何敏感个人信息，即一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人 身、财产安全受到危害的个人信息（例如<strong>社保号、有关种族或族裔出身的信息、宗教或哲学信仰、健康或医疗状况信息、性生活或性取向信息、未披露的犯罪背景信息、特定身份、金融账户</strong>、行踪轨迹、不满十四周岁未成年人的个人信息或为唯一识别个人身份而提供的<strong>生物识别或基因数据</strong>）。</p><br/>
    
    <p><strong>信息自动收集和使用</strong></p>
    <p>我们和我们的服务供应商可在您浏览本服务时，通过以下方式自动收集和使用信息：</p>
    <p style="text-indent: 2em;"><strong>通过浏览器收集：</strong>某些信息由多数浏览器收集，例如您的媒体访问控制（MAC）地址、计算机类型（Windows 或 Mac）、屏幕分辨率、操作系统名称和版本、互联网浏览器类型和版本等。如果您通过移动设备访问本服务，我们可能会收集类似的信息，例如您的设备类型和标识符。如果您通过移动设备访问本服务，我们可能会收集类似的信息，例如您的设备类型和标识符。我们使用这些信息来确保本服务的正常运行。</p>
    <p style="text-indent: 2em;"><strong>使用 cookies: </strong>Cookies是直接存储在您所使用的计算机上的信息。Cookies允许我们收集诸如浏览器类型、使用本服务的时间、访问的页面和语言偏好等信息。我们和我们的服务供应商会出于安全考虑使用该等信息，以方便导航、更有效地显示信息，并帮助您在使用本服务时获得个性化体验。我们还使用cookies来识别您的计算机或设备，从而使您更轻松地使用本服务，例如记住购物车中的内容。此外，我们使用cookies来收集有关服务使用情况的统计信息，以便持续改进其设计和功能，了解个人如何使用本服务，并帮助我们解决与其相关的问题。Cookies还帮助我们选择最有可能吸引您的广告或要约，并在您使用本服务时进行显示。我们也可以在线上广告中使用cookies来跟踪消费者对我们广告的反响。</p>
    <p>您可以按照您的浏览器的说明来拒绝这些cookies; 但是，如果您不接受它们，您在使用本服务时 可能会遇到一些不便。您也可能不会收到来自我们的与您的利益和需求相关的广告或其他要约信息。如了解更多关于cookies的信息，请访问http://allaboutcookies.org。</p>
    <p style="text-indent: 2em;"><strong>使用 Flash cookies：</strong>我们使用Adobe Flash技术(包括Flash本地存储对象(“Flash LSOs”)使我们能够为您提供更多量身定制的信息，方便您持续访问和使用本服务，并收集和存储有关您使用本服务的信息。如果您不希望Flash LSOs存储在您的计算机上，您可以调整Flash播放器的设置，以阻止Flash LSOs存储使用网站存储设置面板中的工具。您还可以通过进入全局存储设置面板并按照其说明控制Flash LSOs。请注意，将Flash播放器设置为限制接受Flash LSOs可能会减少或阻碍某些Flash应用程序的功能使用。</p>
    <p style="text-indent: 2em;"><strong>使用像素标记、网络信标、透明 GIFs或其他类似的技术：</strong>这些技术可能在某些服务页面和HTML格式的电子邮件消息中运用，以跟踪用户和电子邮件接收者的行为，衡量我们的市场营销活动的成果，并编辑有关本服务使用情况的统计数据。</p>
    <p style="text-indent: 2em;"><strong>互联网广告：</strong>当您访问并使用本服务或其他线上服务时，我们可能会根据您在任何设备上访问和使用本服务和其他在线服务的相关信息，利用第三方广告公司为您提供您可能感兴趣的商品和服务的广告。为此，这些公司可能在您的浏览器上放置或识别一个独特的cookie（包括使用像素标签）。他们也可能会使用这些技术，以及他们收集的关于您在线使用的信息，以便在您使用的多个设备（如移动电话和笔记本电脑）上识别您。</p>
    <p style="text-indent: 2em;">如果您想要了解更多有关信息自动收集和使用的信息，并了解如何在访问此隐私政策的特定设备上的桌面浏览器和移动浏览器退出，请访问http://optout.aboutads.info/#/和http://optout.networkadvertising.org/#/。您可以从www.aboutads.info/appchoice下载AppChoices应用程序以退出移动应用程序。</p>
    <p style="text-indent: 2em;"><strong>IP地址：</strong>您的IP地址是一个由您的互联网服务供应商自动分配给您的计算机的号码。每当用户访问 本服务时，IP地址、访问的时间和访问的页面都会被识别并自动记录在我们的服务器日志文件中。收集IP地址是一项标准做法，并且是由许多在线服务自动完成的。我们将IP地址用于计算服务使用 级别、诊断服务器问题和管理本服务等目的。我们也可能会从您的IP地址得到您的大致位置。</p><br/>
    
    <p><strong>我们如何使用和披露信息</strong></p>
    <p>我们会以在收集信息时向您承诺的方式去收集和使用您所提供的个人信息。欲了解如何拒绝我们的某些信息使用和披露，请参阅下面题为“选择和访问”的部分。</p>
    <p>除非法律和行政法规另有规定，我们在收集信息时，将征得您的同意以使用个人信息。在未征得您的额外同意的情况下，我们将严格按照以下情形使用您的个人信息：</p>
    <ul class="ul">
      <li><strong>提供服务的功能并满足您的请求。</strong></li>
      <ul class="child_ul">
        <li>为您提供本服务的功能并提供相关的客户服务；</li>
        <li>答复您的询问并满足您的请求，如发送您所请求的文件或发送电子邮件提醒；</li>
        <li>向您发送有关我们与您的关系或有关本服务的重要信息、我们的条款、条件和政策的变更和/或其他管理信息。</li>
      </ul>
    </ul>
    <ul class="ul">
      <li><strong>实现我们的商业目的。</strong></li>
      <ul class="child_ul">
        <li>用于数据分析，例如，提高本服务的效率；</li>
        <li>用于审计，验证我们的内部流程按预期运行并符合法律、监管或合同的要求；</li>
        <li>出于欺诈监管和安全监控的目的，如检测和防范网络攻击或企图窃取身份的行为；</li>
        <li>为开发新产品和服务；</li>
        <li>为增强、改进或修改我们的网站或产品和服务；</li>
        <li>为确定本服务的使用趋势，例如，了解我们的服务的哪些部分是用户最感兴趣的；</li>
        <li>为确定我们的促销活动的有效性，以便我们能够根据用户的需要和利益调整我们的活动。</li>
      </ul>
    </ul>
    <ul class="ul">
      <li><strong>分析个人信息以提供个性化的服务。</strong></li>
      <ul class="child_ul">
        <li>为更好地了解您，以便我们能够个性化地与您的互动，并为您提供量身定制的信息和/或要约；</li>
        <li>为更好地了解您的偏好，以便我们能够通过本服务提供我们认为与您相关和您感兴趣的内容。</li>
      </ul>
    </ul>
    <p>我们也将向以下主体披露通过本服务收集的信息：</p>
    <ul class="ul">
      <li>出于本隐私政策所述的目的，向我们的关联方进行披露。我们的关联方名单可在https://johnsonandjohnson.gcs-web.com/financial-information/sec-filings（https:/ljohnsonandjohnson.gcs-web.com/financial-information/sec-filings）中的“美国证监会备案”附件21,表10K中查询。强生（上海）医疗器材有限公司是负责管理共同使用的个人信息的责任人；</li>
      <li>与我们进行联合品牌或捆绑促销的第三方合作伙伴；</li>
      <li>向我们提供网站托管与调节、移动应用程序托管、数据分析、支付处理、订单履行、基础设施 提供、 IT 服务、客户服务、电子邮件和直邮服务、审计等第三方服务商，以使其能够提供服务；</li>
      <li>在适用法律允许的情况下，在我们的全部或部分业务、资产或股票发生重组、兼并、出售、合资、转让、转移或以其他方式处置时（包括与破产或类似程序有关的情况）,向第三方服务供应商披露。</li>
    </ul>
    <p>此外，我们可能在我们认为必要或适当的情况下使用和披露您的信息：（a）遵守法律程序或适用法律，可能包括您居住国以外的法律；（b）在适用法律允许的情况下，回应公共和政府部门的请求，可能包括您居住国以外的政府部门；（c）执行我们的条款和条件；以及（d）保护我们的权利、隐私、安全或财产，以及/或我们的关联公司、您或其他人的权利、隐私、安全或财产。在获得您的同意后，我们还可以以其他方式使用和披露您的信息。</p>
    <p>我们可以以“信息自动收集和使用”中所述的方式使用和披露我们自动收集的信息。</p>
    <p>此外，在适用法律允许的情况下，我们可以为任何目的使用和披露无法识别个人身份的信息。如果我们将无法识别个人身份的信息与可识别的信息（例如将您的姓名与您的地理位置合并）合并，一旦合并，我们将视合并后的信息为个人信息。</p><br/>

    <p><strong>选择和访问</strong></p>
    <p><strong>您关于我们使用和披露您的个人信息的选择</strong></p>
    <p>我们向您提供关于我们出于营销目的使用和披露您的个人信息的选项。在以下情形中，您可以选择退出：</p>
    <ul class="ul">
      <li>接收我们的营销通信：如果您未来不再希望接收我们的营销通信，您可以通过电话800-820-1000（固话拨打）或者400-888-3090的方式联系我们，选择不接收营销通信。在您向我们提出的请求中，请提供您的姓名，您不再希望接收的营销通信形式，以及接收地址。例如，如果您不再希望收到我们的营销电子邮件或信件，请告知我们，并提供您的姓名、电子邮件或邮寄地址。此外，您可以按照任何此类通信中提供的退订说明来选择不接收来自我们的营销电子邮件。</li>
      <li>收到我们的提醒：如果您未来不再希望继续收到我们的医疗提醒，您可以通过电话800-820-1000（固话拨打）或者400-888-3090（手机拨打）的方式选择不接收这些提醒。在您向我们提出的请求中，请提供您的姓名，和您接收我们提醒的电子邮箱地址或手机号码。</li>
      <li>我们与关联方和第三方合作伙伴共享您的个人信息：如果您曾经选择接受我们的关联方或第三方合作伙伴的营销通信，您可以通过电话800-820-1000（固话拨打）或者400-888-3090（手机拨打）的方式联系我们，拒绝我们与这些合作伙伴出于直接营销目的共享您的个人信息。在您给我们的通信中，请说明我们不会再与我们的关联方和/或第三方合作伙伴分享您的个人信息，并请提供您的姓名和电子邮件地址。</li>
    </ul>
    <p>我们将在合理可行的情况下尽快满足您的请求。请注意，如果您如上所述选择退出，我们可能无法直接从我们已与之共享您信息的关联方的数据库中删除您的个人信息（例如，在我们执行您的选择退出请求之日）。但是，我们会尽合理的努力将您的要求通知我们的关联方。请注意，如果您选择不接受我们的营销相关信息，我们仍然可能会向您发送重要的交易和管理信息，您不能选择退出。</p>
    <p><strong>您如何访问、更改、撤回同意或删除您的个人信息</strong></p>
    <p>本网站不收集您的个人信息。如果您从其他途径向我们提供了个人信息，并且如果您希望审阅、更正、更新、限制或删除您的个人信息，或者您希望撤回关于我们处理您个人信息的同意（包括注销您的账号）,或者您希望索取您的个人信息的电子副本以便将其传送给其他公司（在适用法 律赋予您这些权利的范围内）,或者您希望删除您的账户，请通过收集您个人信息的渠道所适用的隐私政策中的方式。我们将在合理可行的情况下尽快且不迟于收到您的请求后一个月内予以答复。如果情况导致我们延迟回复，我们将及时通知您，并提供我们回复的日期。</p><br/>

    <p><strong>跨境传输</strong></p>
    <p>我们在中国境内收集和产生的个人信息都将存放在中国境内，适用法律规定允许跨境传输的除外。</p>
    <p>您的个人信息可能会在我们设有机构或服务供应商的任何国家存储和处理。通过使用我们的服务或授权给我们（如法律要求）,您的个人信息可能会被转移到您居住国以外的国家，包括美国。美国可能会提供与您居住国不同的数据保护规则。但是，当您的个人信息被转移给我们在其他国家的分支机构或第三方时，我们将采取适当的合同和其他措施来保护您的个人信息。</p><br/>

    <p><strong>安全</strong></p>
    <p>我们寻求使用合理的组织、技术和管理措施来保护我们控制下的个人信息。但是，通过互联网或数据存储系统传输的数据无法保证100%的安全。如果您有理由认为您与我们的互动不再安全（例如，如果您认为您于我们的任何账户的安全受到威胁）,请立即根据下面的“联系我们”一节通知我们。</p><br/>

    <p><strong>保留期限</strong></p>
    <p>我们将根据收集您的个人信息时所述的目的，在需要或允许的最短的时间期限内保留您的个人信息。用于确定我们保留期限的标准包括：（i）我们与您保持持续关系并向您提供服务的时间长度；（ii）是否存在约束我们的法律义务；以及（iii）根据我们的法律地位（例如适用的诉讼时效、诉讼或监管调查）。</p>
    <p>在必要的保留时间后，除非法律、行政法规另有规定或您同意继续保留信息，我们将删除您的个人信息。</p><br />

    <p><strong>第三方网站和服务</strong></p>
    <p>本服务可能包含指向第三方网站的链接。本隐私政策不涉及任何第三方（包括运营通过本服务提供的任何网站或在线服务（包括但不限于任何应用程序）或包含链接的任何第三方的隐私、信息 或行为，并且我们对这些行为不承担任何责任。在本服务上提供或包含任何该等网站或资源的链接并不意味着我们或我们的分支机构认可该等网站或资源。</p><br />

    <p><strong>联系我们</strong></p>
    <p><strong>强生（上海）医疗器材有限公司位于上海市徐汇区桂箐路65号新研大厦A楼</strong>，是根据本隐私政策负责收集、使用和披露个人信息的公司。</p>
    <p>如果您对本隐私政策有任何疑问，请通过电话800-820-1000（固话拨打）或者400-888-3090（手机拨打）与我们联系，或写信至以下地址：</p>
    <p style="text-indent: 2em;"><strong>上海市徐汇区桂箐路65号新研大厦A楼</strong></p><br />
    <!-- <p style="text-indent: 2em;"><strong>邮编： 200233</strong></p>
    <p style="text-indent: 2em;"><strong>联系人：强生（上海）医疗器材有限公司</strong></p>
    <p style="text-indent: 2em;"><strong>电话：800-820-1000（固话拨打）</strong></p>
    <p style="text-indent: 5em;"><strong>400-888-3090（手机拨打）</strong></p><br /> -->

    <p><strong>对本隐私政策的更新</strong></p>
    <p>我们可能会更改本隐私政策。本隐私政策的任何更改将在我们将修改后的隐私政策发布于本服务时生效。您在这些更改后使用本服务意味着您接受修改后的隐私政策，但我们会根据适用法律的要求重新获得您的同意。我们建议您访问本服务时定期查阅隐私政策。<strong>[2022年8月31日]</strong></p>

  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  font-size: 12px;
  padding: 12px;
  box-sizing: border-box;
  background-color: #F8F8F8;
  .title {
    font-size: 15px;
    text-align: center;
    font-weight: bold;
  }
  p {
    line-height: 2;
  }
  .ul {
    list-style-type: disc;
    margin-left: 30px;
    li {
      line-height: 2;
    }
  }
  .child_ul {
    list-style-type: circle;
    margin-left: 14px;
    li {
      line-height: 2;
    }
  }
}
</style>